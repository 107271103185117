import React, { useEffect, useState } from 'react';
import Layout from '@components/Layout';
import TopSection from '../Sections/TopSection/TopSection';
import Featured from '../Sections/Featuredproducts/Featured';

import Store from './store';
import { Grid, Col, Row } from '@components/Grid';
import Product from '../components/ProductCard/Product';
import { graphql, Link } from 'gatsby';
import SEO from '../components/SEO';
import { Divider } from '../components/Grid';
import { connect } from 'react-redux';
import queryString from 'query-string';
import { navigate } from '@reach/router';
const Home = ({
    location,
    category,
    data: {
        merlinStore: { products },
        merlinWebsite: {
            store: {
                currency: { symbol: currencySymbol },
            },
        },
        allMerlinStoreCategory: { nodes: merlinStoreCategories },
    },
}) => {
    const [client, setClient] = useState(false);
    const currentCategory =
        !!merlinStoreCategories && merlinStoreCategories.find(({ _id }) => _id === category);

    const filteredProducts = client
        ? products
              .map((product) => {
                  if (!category) {
                      return product;
                  }

                  if (product.category === category) {
                      return product;
                  }
              })
              .filter((x) => !!x)
        : products;

    useEffect(() => {
        setClient(true);
    }, []);

    return (
        <Layout>
            <TopSection />
            {((client && !currentCategory) || !client) && <Featured />}

            <Grid noMargin>
                {client && !!currentCategory && (
                    <div>
                        <Divider margin={5} />
                        <h1>{currentCategory?.name}</h1>

                        <p
                            onClick={() =>
                                navigate(
                                    `${location.pathname}?${queryString.stringify({
                                        ...queryString.parse(location.search),
                                        category: undefined,
                                    })}`
                                )
                            }
                            style={{ cursor: 'pointer' }}
                        >
                            <Link style={{ textDecoration: 'none', color: 'black' }} to="/">
                                Remove Tag
                            </Link>
                        </p>
                    </div>
                )}

                <Row>
                    {filteredProducts?.map((product) => {
                        return (
                            <Col xs={12} md={6} lg={4} key={product._id}>
                                <Product data={product} currencySymbol={currencySymbol} />
                            </Col>
                        );
                    })}
                </Row>
            </Grid>
        </Layout>
    );
};

export const query = graphql`
    query {
        merlinWebsite {
            websiteId
            store {
                name
                description
                currency {
                    symbol
                }
            }
        }
        allMerlinStoreCategory {
            nodes {
                _id
                name
            }
        }
        merlinStore: allMerlinStoreProduct {
            products: nodes {
                _id
                shortName
                name
                description
                price
                tags
                images {
                    image: url {
                        childImageSharp {
                            fluid(maxHeight: 600) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
            }
        }
    }
`;

export default connect((state, ownProps) => {
    const params = queryString.parse(ownProps.location.search);

    return {
        category: params.category,
    };
})(Home);
