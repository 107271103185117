import React, { Fragment } from 'react';
import styles from './ProductCard.module.scss';
import Img from 'gatsby-image';
import ImageSlider from '../ImageSlider';
import { useStaticQuery, graphql, Link } from 'gatsby';
import { Grid, Row, Col } from '@components/Grid';
import Button from '@components/Button';
import { addToBasket } from '@actions/basket';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { handleError } from '@helpers/errors';
import { getBasket } from '@helpers/store';
import { makePutRequest, makePostRequest } from '@helpers/requests';
import { BASKET_ADD, BASKET_CREATE } from '@helpers/api';

const Product = ({ data, currencySymbol, basketId, addToBasket }) => {
    const { _id: id, shortName, name, price, images, description } = data;

    const {
        Product: {
            frontmatter: { productimage, productimageback },
        },
        merlinStore: { products },
        merlinWebsite: { websiteId },
    } = useStaticQuery(graphql`
        query Product {
            merlinWebsite {
                websiteId
            }
            Product: markdownRemark(fileAbsolutePath: { regex: "/home/" }) {
                frontmatter {
                    productimage {
                        childImageSharp {
                            fluid(maxWidth: 2000) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
            }
            merlinStore: allMerlinStoreProduct {
                products: nodes {
                    _id
                }
            }
        }
    `);
    const basket = getBasket(products);
    const handleAddToBasket = async () => {
        try {
            if (basketId) {
                await makePutRequest(
                    BASKET_ADD(basketId),
                    {
                        productId: id,
                        quantity: ((basket.find((item) => item.id === id) || {}).quantity || 0) + 1,
                    },
                    { websiteId }
                );

                addToBasket({ id, quantity: 1 });
            } else {
                const { data: basketData } = await makePostRequest(
                    BASKET_CREATE,
                    { productId: id, quantity: 1 },
                    { websiteId }
                );

                addToBasket({ id, quantity: 1, basketId: basketData._id });
            }
        } catch (error) {
            handleError(error);
        }
    };
    return (
        <div className={styles.product}>
            <Link style={{ textDecoration: 'none', color: 'black' }} to={`/store/${shortName}`}>
                {images && images[0] && images[0].image ? (
                    <Img fluid={images[0].image.childImageSharp.fluid} alt={name} />
                ) : (
                    <Img fluid={productimage.childImageSharp.fluid} />
                )}
            </Link>
            <div className={styles.productData}>
                <Row>
                    <Col className={styles.price} xs={6}>
                        {currencySymbol}
                        {parseFloat(price / 100).toFixed(2)}
                    </Col>
                    <Col className={styles.status} xs={6}>
                        <div onClick={() => handleAddToBasket()}>Add to Cart</div>
                    </Col>
                </Row>
                <Row className={styles.special}>
                    <div>{name}</div>
                </Row>
            </div>
        </div>
    );
};

export default connect(
    ({ basket }) => ({ basketId: basket ? basket.id : null }),
    (dispatch) => bindActionCreators({ addToBasket }, dispatch)
)(Product);
