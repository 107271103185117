import React from 'react';
import styles from './Featured.module.scss';
import { Grid, Row, Col } from '@components/Grid';
import Product from '../../components/ProductCard/Product';
import { useStaticQuery, graphql } from 'gatsby';
const Featured = () => {
    const {
        featured: {
            frontmatter: { less },
        },
        merlinWebsite: {
            store: {
                currency: { symbol: currencySymbol },
            },
        },
        merlinStore: { products },
    } = useStaticQuery(graphql`
        query {
            merlinWebsite {
                websiteId
                store {
                    name
                    description
                    currency {
                        symbol
                    }
                }
            }

            featured: markdownRemark(fileAbsolutePath: { regex: "/featured-products/" }) {
                frontmatter {
                    less: less_featured_product_ids {
                        id
                    }
                }
            }
            merlinStore: allMerlinStoreProduct {
                products: nodes {
                    _id
                    shortName
                    name
                    description
                    price
                    tags
                    images {
                        image: url {
                            childImageSharp {
                                fluid(maxHeight: 600) {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
                    }
                }
            }
        }
    `);
    const lessFeaturedProducts = less.map((val) => {
        return products.find((item) => item._id === val.id);
    });
    return (
        <div className={styles.featuredMain}>
            <Grid noMargin>
                <Row>
                    <Col xs={12} md={5}>
                        <Product
                            data={lessFeaturedProducts && lessFeaturedProducts[0]}
                            currencySymbol={currencySymbol}
                        />
                    </Col>
                    <Col xs={0} md={2}></Col>
                    <Col xs={12} md={5}>
                        {' '}
                        <Product
                            data={lessFeaturedProducts && lessFeaturedProducts[1]}
                            currencySymbol={currencySymbol}
                        />
                    </Col>
                </Row>
            </Grid>
        </div>
    );
};

export default Featured;
