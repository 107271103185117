import React from 'react';
import styles from './TopSection.module.scss';

import { Grid, Row, Col } from '@components/Grid';
import Product from '@components/ProductCard/Product';
import { useStaticQuery, graphql, Link } from 'gatsby';
import { Location, navigate } from '@reach/router';
import queryString from 'query-string';
const TopSection = ({ location }) => {
    const {
        logout: {
            frontmatter: { logout },
        },

        merlinWebsite: {
            store: {
                currency: { symbol: currencySymbol },
            },
        },
        merlinStore: { products },
        allMerlinStoreCategory: { nodes: merlinStoreCategories },
        featured: {
            frontmatter: { top_id },
        },
    } = useStaticQuery(graphql`
        query Logout {
            logout: markdownRemark(fileAbsolutePath: { regex: "/home/" }) {
                frontmatter {
                    logout {
                        publicURL
                    }
                }
            }
            merlinWebsite {
                websiteId
                store {
                    name
                    description
                    currency {
                        symbol
                    }
                }
            }

            featured: markdownRemark(fileAbsolutePath: { regex: "/featured-products/" }) {
                frontmatter {
                    top_id: top_featured_product_id
                }
            }
            merlinStore: allMerlinStoreProduct {
                products: nodes {
                    _id
                    shortName
                    name
                    description
                    price
                    tags
                    images {
                        image: url {
                            childImageSharp {
                                fluid(maxHeight: 600) {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
                    }
                }
            }
            allMerlinStoreCategory {
                nodes {
                    _id
                    name
                }
            }
        }
    `);
    const merlinStoreFeaturedProduct = products.find((item) => item._id === top_id);
    return (
        <Grid noMargin>
            <div className={styles.topMain}>
                <div className={styles.seriesColumn}>
                    <div className={styles.series}>
                        <div className={styles.navigationHeading}>
                            <h2>Series</h2>
                            <span>&#9776;</span>
                        </div>
                        <div className={styles.navigations}>
                            <ul>
                                {merlinStoreCategories.map(({ _id, name }) => (
                                    <li key={_id}>
                                        <Link
                                            to={`${location.pathname}?${queryString.stringify({
                                                ...queryString.parse(location.search),
                                                category: _id,
                                            })}`}
                                        >
                                            {name}
                                        </Link>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
                <div className={styles.productColumn}>
                    <div className={styles.productWrapper}>
                        <Product
                            data={merlinStoreFeaturedProduct}
                            currencySymbol={currencySymbol}
                        />
                    </div>
                </div>
            </div>
        </Grid>
    );
};

export default (props) => (
    <Location>{(locationContext) => <TopSection {...locationContext} {...props} />}</Location>
);
